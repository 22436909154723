import Vue from 'vue';
import { blockScroll, enableScroll } from '@/plugins/helper';
export default Vue.extend({
    name: 'LoadingComponent',
    data: function () {
        return {
            loading: true
        };
    },
    mounted: function () {
        this.start();
    },
    methods: {
        start: function () {
            this.loading = true;
            blockScroll();
        },
        finish: function () {
            this.loading = false;
            enableScroll();
        }
    }
});
