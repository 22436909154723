import { ref } from 'vue';
import { debounce } from '~/plugins/helper';
var breakpoints = {
    sp: 768,
    tablet: 1171 // < 1171
};
export var Devices;
(function (Devices) {
    Devices["sp"] = "sp";
    Devices["tablet"] = "tablet";
    Devices["desktop"] = "desktop";
})(Devices || (Devices = {}));
var device = ref(Devices.sp);
var isMobile = ref(device.value === Devices.sp);
export function useDevice() {
    var width = ref(window.innerWidth);
    var onResize = function () {
        if (window.innerWidth !== width.value) {
            width.value = window.innerWidth;
            setDevice();
        }
    };
    var setDevice = function () {
        if (width.value < breakpoints.sp) {
            device.value = Devices.sp;
            isMobile.value = true;
        }
        else if (width.value >= breakpoints.sp && width.value < breakpoints.tablet) {
            device.value = Devices.tablet;
            isMobile.value = false;
        }
        else {
            device.value = Devices.desktop;
            isMobile.value = false;
        }
    };
    var init = function () {
        setDevice();
        window.addEventListener('resize', debounce(onResize, 30));
    };
    return {
        device: device,
        isMobile: isMobile,
        init: init
    };
}
