import { defineComponent } from 'vue';
import { useDevice } from '@/composables/use-device';
import { useMenu } from '~/composables/use-menu';
// @ts-ignore
var imagesLoaded = require('imagesloaded');
var selfHandleLoadingPages = ['areaguide'];
export default defineComponent({
    data: function () {
        return {
            main: true,
            historyLen: 0
        };
    },
    watch: {
        $route: function () {
            var _this = this;
            this.$nextTick(function () {
                _this.main = _this.$route.name === 'index';
                window.setTimeout(function () {
                    // fix back in browser
                    // if (window.history.length > this.historyLen) {
                    // window.scrollTo(0, 0)
                    // }
                    _this.historyLen = window.history.length;
                    _this.checkLocation();
                }, 350);
            });
        }
    },
    created: function () {
        var _a = useMenu(), getNews = _a.getNews, getTopSetting = _a.getTopSetting;
        getNews();
        getTopSetting();
    },
    mounted: function () {
        var _this = this;
        this.$nextTick(function () {
            _this.main = _this.$route.name === 'index';
            _this.historyLen = window.history.length;
            _this.handleRouteChange();
            var init = useDevice().init;
            init();
        });
    },
    methods: {
        checkLocation: function () {
            var isMobile = useDevice().isMobile;
            var _a = this.$route, hash = _a.hash, query = _a.query;
            var section = query.s;
            var id = "#" + (section || (hash === null || hash === void 0 ? void 0 : hash.replace('#', ''))) + "-section";
            var element = document.querySelector(id);
            if (!element) {
                return;
            }
            this.$scrollTo(element, {
                duration: 500,
                offset: isMobile ? 0 : -100
                // onDone: () => {
                // fix fb 15/11/2022
                // history.replaceState('', document.title, window.location.pathname)
                // }
            });
        },
        handleRouteChange: function () {
            var _this = this;
            this.$nuxt.$loading.start();
            var actions = function () {
                window.scrollTo(0, 0);
                // hide loading excepts self handle loading pages
                if (!selfHandleLoadingPages.includes(_this.$route.name)) {
                    _this.$nuxt.$loading.finish();
                }
                window.addEventListener('load', function () {
                    window.setTimeout(_this.checkLocation, 500);
                });
            };
            var imageBody = this.$route.name === 'index' ? '#kvCarousel' : 'body';
            var imgLoaded = imagesLoaded(imageBody, { background: true });
            imgLoaded.on('done', actions);
            imgLoaded.on('fail', actions);
        }
    }
});
