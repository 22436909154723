import { defineComponent, ref } from 'vue';
import { useSns } from '@/composables/use-sns';
import config from '@/configs';
export default defineComponent({
    name: 'CShareSns',
    setup: function () {
        var shareLineLink = ref('');
        var shareTwitterLink = ref('');
        var shareFacebookLink = ref('');
        return { shareFacebookLink: shareFacebookLink, shareLineLink: shareLineLink, shareTwitterLink: shareTwitterLink };
    },
    computed: {
        isAngielacoquette: function () {
            return this.$route.name === 'angielacoquette';
        }
    },
    mounted: function () {
        var SnsHashTag = '#ボールパークファンタジア';
        var url = config.shareUrl + '\n';
        var snsContent = 'BALLPARK FANTASIA（ボールパークファンタジア）';
        var _a = useSns(), shareLine = _a.shareLine, shareTwitter = _a.shareTwitter, shareFacebook = _a.shareFacebook;
        var isSp = window.innerWidth < 768;
        this.shareLineLink = shareLine({ url: url, message: snsContent, isSp: isSp, hashtags: SnsHashTag });
        this.shareTwitterLink = shareTwitter({ url: url, message: snsContent, hashtags: SnsHashTag });
        this.shareFacebookLink = shareFacebook({ url: url, message: snsContent });
        if (this.isAngielacoquette) {
            this.shareFacebookLink = shareFacebook({ url: 'https://ballparkfantasia.com/angielacoquette/\n', message: '' });
            this.shareLineLink = shareLine({ url: 'https://ballparkfantasia.com/angielacoquette/\n', message: '関内いいとこどり 異日常さんぽ♪ アンジー・ラ・コケット☆麗しのアンジー', isSp: isSp, hashtags: '#ボールパークファンタジア' });
            this.shareTwitterLink = shareTwitter({ url: 'https://ballparkfantasia.com/angielacoquette/\n', message: '関内いいとこどり 異日常さんぽ♪ アンジー・ラ・コケット☆麗しのアンジー', hashtags: '#ボールパークファンタジア' });
        }
        console.log(this);
    }
});
