import clientAPI from '~/services/axios-config';
var API_NEWS = process.env.API_NEWS || '/api/data/news';
var API_TOKEN = process.env.API_TOKEN || '7528-313-407-027-650-941-393-981';
var INSTAGRAM_URL = process.env.INSTAGRAM_URL || '';
var API_TOP_SETTING = process.env.API_TOP_SETTING || '/api/data/top-setting';
var API_AREAGUIDE_SETTING = process.env.API_TOP_SETTING || '/api/data/area-guide/setting';
var API_AREAGUIDE_EVENTS = process.env.API_TOP_SETTING || '/api/data/area-guide/events';
var apiHeaderName = 'X-api-key';
var request = function (url) {
    var _a;
    return clientAPI({ headers: (_a = {}, _a[apiHeaderName] = API_TOKEN, _a) }).get(url);
};
export default {
    getNews: function () {
        return request(API_NEWS);
    },
    getInstagramMedia: function () {
        return clientAPI().get(INSTAGRAM_URL);
    },
    getTopSetting: function () {
        return request(API_TOP_SETTING);
    },
    getAreaguideSetting: function () {
        return request(API_AREAGUIDE_SETTING);
    },
    getAreaguideEvents: function () {
        return request(API_AREAGUIDE_EVENTS);
    }
};
