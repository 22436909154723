import 'intersection-observer';
import Vue from 'vue';
import IntersectionObserverElement, { CacheKey } from './IntersectionObserverElement';
export default (function (_ctx, inject) {
    inject('createIntersectionObserverElement', createIntersectionObserverElement);
});
/**
 *
 * @param {element} element Target element
 * @param {object} options Options
 * @return {IntersectionObserverElement} Observer
 */
function createIntersectionObserverElement(element, options) {
    var observer = new IntersectionObserverElement(element, options);
    element[CacheKey] = observer;
    return observer;
}
Vue.directive('intersection-observer', {
    inserted: function (element, binding) {
        var options = binding.value || {};
        if (typeof options === 'function') {
            options = {
                callback: options
            };
        }
        if (binding.modifiers.once) {
            options.once = true;
        }
        createIntersectionObserverElement(element, options);
    }
});
