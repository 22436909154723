var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import config from '../configs';
var pageY = 0;
function meta(name, content, extend) {
    if (name === void 0) { name = ''; }
    if (content === void 0) { content = ''; }
    if (extend === void 0) { extend = {}; }
    return __assign({ name: name, hid: name, content: content }, extend);
}
function metaProperty(property, content, extend) {
    if (property === void 0) { property = ''; }
    if (content === void 0) { content = ''; }
    if (extend === void 0) { extend = {}; }
    return __assign({ property: property, hid: property, content: content }, extend);
}
function link(rel, href, type, extend) {
    if (rel === void 0) { rel = ''; }
    if (href === void 0) { href = ''; }
    if (type === void 0) { type = ''; }
    if (extend === void 0) { extend = {}; }
    return __assign({ rel: rel,
        type: type,
        href: href }, extend);
}
function setMetaTitleAndDescription(title, description, titleTemplate) {
    if (title === void 0) { title = config.title; }
    if (description === void 0) { description = config.description; }
    if (titleTemplate === void 0) { titleTemplate = true; }
    return [
        metaProperty('og:title', title, { template: titleTemplate ? config.titleTemplate : undefined }),
        meta('twitter:title', title, { template: titleTemplate ? config.titleTemplate : undefined }),
        meta('description', description),
        metaProperty('og:description', description),
        meta('twitter:description', description)
    ];
}
function blockScroll() {
    var html = document.documentElement;
    pageY = window.scrollY;
    html.style.overflow = 'hidden';
    html.style.top = "-" + pageY + "px";
    html.style.width = '100%';
    html.style.height = window.innerHeight + 'px';
}
function enableScroll() {
    var html = document.documentElement;
    html.style.removeProperty('overflow');
    html.style.removeProperty('top');
    html.style.removeProperty('width');
    html.style.removeProperty('height');
    window.scrollTo(0, pageY);
}
function handleError(err) {
    // eslint-disable-next-line no-console
    console.log(err);
}
function debounce(func, wait) {
    if (wait === void 0) { wait = 20; }
    var timeout;
    return function executedFunction() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var later = function () {
            timeout = null;
            func.apply(void 0, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
function generateSaveQuery(value) {
    return value === null || value === void 0 ? void 0 : value.replace(/[?#&#%+<>"':;= ]/, '-');
}
export { blockScroll, debounce, enableScroll, generateSaveQuery, handleError, link, meta, metaProperty, setMetaTitleAndDescription };
var helper = {
    meta: meta,
    metaProperty: metaProperty,
    link: link,
    setMetaTitleAndDescription: setMetaTitleAndDescription,
    handleError: handleError,
    blockScroll: blockScroll,
    enableScroll: enableScroll,
    debounce: debounce,
    generateSaveQuery: generateSaveQuery
};
export default (function (ctx, inject) {
    // @ts-ignore
    ctx.$helper = helper;
    inject('helper', helper);
});
