import Vue from 'vue';
import { Autoplay, Navigation, Pagination, Swiper } from 'swiper';
var swiper = {
    // @ts-ignore
    install: function () {
        Vue.prototype.$swiper = Swiper;
        Vue.prototype.$swiperModules = {
            Pagination: Pagination,
            Navigation: Navigation,
            Autoplay: Autoplay
        };
    }
};
Vue.use(swiper);
