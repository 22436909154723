import { ref } from 'vue';
import { debounce } from '@/plugins/helper';
export function useScroll() {
    var isShowMenu = ref(false);
    var onScroll = debounce(function () {
        isShowMenu.value = window.scrollY > 200;
    }, 30);
    var registerScrollEvent = function () {
        window.addEventListener('scroll', onScroll);
    };
    var removeScrollEvent = function () {
        window.removeEventListener('scroll', onScroll);
    };
    var init = function () {
        if (window.innerWidth >= 768) {
            registerScrollEvent();
        }
        else {
            removeScrollEvent();
        }
    };
    var onResize = function (callback) {
        window.addEventListener('resize', debounce(function () {
            init();
            if (callback) {
                callback();
            }
        }, 30));
    };
    return {
        isShowMenu: isShowMenu,
        registerScrollEvent: registerScrollEvent,
        removeScrollEvent: removeScrollEvent,
        init: init,
        onResize: onResize
    };
}
