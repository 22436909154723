import { ref } from 'vue';
import APIService from '@/services';
var isShowPickupMenu = ref(false);
var isShowInformationMenu = ref(false);
var pickUpData = ref([]);
var partnerData = ref([]);
var inforNews = ref(null);
export function useMenu() {
    // get pick up
    var getTopSetting = function () {
        APIService.getTopSetting().then(function (response) {
            var _a, _b, _c, _d;
            if (response === null || response === void 0 ? void 0 : response.data) {
                pickUpData.value = ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.pickups) || [];
                isShowPickupMenu.value = pickUpData.value.length > 0;
                partnerData.value = ((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.partners) || [];
            }
        });
    };
    // get news
    var getNews = function () {
        APIService.getNews().then(function (responsive) {
            if (responsive) {
                var data = responsive.data;
                inforNews.value = data.news || null;
                isShowInformationMenu.value = !!inforNews.value;
            }
        });
    };
    return {
        getTopSetting: getTopSetting,
        getNews: getNews,
        isShowPickupMenu: isShowPickupMenu,
        isShowInformationMenu: isShowInformationMenu,
        pickUpData: pickUpData,
        inforNews: inforNews,
        partnerData: partnerData
    };
}
