export default {
    title: 'BALLPARK ADVENTURE by BALLPARK FANTASIA',
    titleTemplate: '',
    description: '2020年のスタートから今年で5年目を迎えた「ボールパークファンタジア」。今年は7月、9月、11月の3回の開催としてパワーアップ。第3回は「BALLPARK ADVENTURE by BALLPARK FANTASIA」と題し、「アソビで可能性を引き出す」をコンセプトに親子で楽しめるアクティビティコンテンツが横浜公園に登場！',
    url: {
        dev: 'https://dev.ballparkfantasia.com',
        prod: 'https://ballparkfantasia.com'
    },
    locale: 'ja_JP',
    site_name: 'BALLPARK ADVENTURE by BALLPARK FANTASIA',
    version: '1.0',
    app_name: 'BPF',
    sns: {
        twitter: 'https://twitter.com/bp_fantasia/',
        instagram: 'https://www.instagram.com/ballpark_fantasia/'
    },
    shareUrl: 'https://ballparkfantasia.com'
};
