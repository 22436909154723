var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DefaultOptions = {
    y: '0px',
    ratio: 0,
    initClassName: 'observer',
    visibleClassName: 'visible',
    unVisibleClassName: 'un-visible',
    once: false,
    direction: false,
    callback: function () { }
};
export var CacheKey = '_intersectionObserverElement';
var IntersectionObserverElement = /** @class */ (function () {
    function IntersectionObserverElement(element, options) {
        this.element = null;
        this.options = DefaultOptions;
        this.observer = null;
        this.isVisible = false;
        this.prevRatio = 0;
        this.direction = '';
        this.element = element;
        this.options = __assign(__assign({}, DefaultOptions), (options || {}));
        this.bind();
        this.element.classList.add(this.options.initClassName);
    }
    IntersectionObserverElement.prototype.bind = function () {
        if (this.observer) {
            return;
        }
        this.observer = new IntersectionObserver(this.onObserver.bind(this), {
            rootMargin: this.options.y + " 0px",
            threshold: [this.options.ratio]
        });
        this.observer.observe(this.element);
    };
    IntersectionObserverElement.prototype.unbind = function () {
        if (!this.observer) {
            return;
        }
        this.observer.disconnect();
        this.observer = null;
        this.element[CacheKey] = null;
    };
    IntersectionObserverElement.prototype.onVisible = function () {
        this.element.classList.remove(this.options.unVisibleClassName);
        this.element.classList.add(this.options.visibleClassName);
    };
    IntersectionObserverElement.prototype.onUnVisible = function () {
        this.element.classList.remove(this.options.visibleClassName);
        this.element.classList.add(this.options.unVisibleClassName);
    };
    IntersectionObserverElement.prototype.onObserver = function (entries) {
        var entry = entries[0];
        var isVisible = entry.isIntersecting;
        if (this.options.direction) {
            var bottom = entry.target.getBoundingClientRect().bottom;
            if (bottom < this.prevRatio) {
                this.direction = 'up';
            }
            else {
                this.direction = 'down';
            }
            this.prevRatio = bottom;
        }
        if (this.isVisible === isVisible) {
            return;
        }
        this.isVisible = isVisible;
        if (this.isVisible) {
            this.onVisible();
        }
        else {
            this.onUnVisible();
        }
        if (this.options.callback) {
            this.options.callback(this);
        }
        if (this.isVisible && this.options.once) {
            this.unbind();
        }
    };
    return IntersectionObserverElement;
}());
export default IntersectionObserverElement;
