var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { defineComponent } from 'vue';
import config from '@/configs';
import { MenuList } from '@/constant/menu';
import { useMenu } from '~/composables/use-menu';
export default defineComponent({
    setup: function () {
        var instagramUrl = config.sns.instagram;
        var twitterUrl = config.sns.twitter;
        var menuList = __spreadArray([], MenuList);
        var isShowInformationMenu = useMenu().isShowInformationMenu;
        return {
            instagramUrl: instagramUrl,
            twitterUrl: twitterUrl,
            menuList: menuList,
            isShowInformationMenu: isShowInformationMenu
        };
    },
    methods: {
        handleClickMenuItem: function (hash) {
            var name = this.$route.name;
            if (name === 'index') {
                this.$scrollTo(hash + "-section");
            }
            else {
                this.$router.push("/" + hash);
            }
        }
    }
});
