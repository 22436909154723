var FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';
var TWITTER_SHARE_URL = 'http://twitter.com/intent/tweet';
var LINE_SHARE_SP_URL = 'http://line.me/R/msg/text/';
var LINE_SHARE_PC_URL = 'https://social-plugins.line.me/lineit/share';
export function useSns() {
    var shareFacebook = function (_a) {
        var message = _a.message, url = _a.url;
        return FACEBOOK_SHARE_URL + "?quote=" + encodeURIComponent(message) + "&u=" + encodeURIComponent(url);
    };
    var shareTwitter = function (_a) {
        var message = _a.message, url = _a.url, _b = _a.hashtags, hashtags = _b === void 0 ? '' : _b;
        var hashtag = hashtags.slice(1, hashtags.length);
        return TWITTER_SHARE_URL + "?text=" + encodeURIComponent(message) + "&url=" + encodeURIComponent(url) + "&hashtags=" + encodeURIComponent(hashtag);
    };
    var shareLine = function (_a) {
        var url = _a.url, message = _a.message, isSp = _a.isSp, hashtags = _a.hashtags;
        var text = message + url + hashtags;
        var encodeMsg = encodeURIComponent(text);
        if (isSp) {
            return LINE_SHARE_SP_URL + "?" + encodeMsg;
        }
        else {
            return LINE_SHARE_PC_URL + "?text=" + encodeMsg;
        }
    };
    return {
        shareFacebook: shareFacebook,
        shareTwitter: shareTwitter,
        shareLine: shareLine
    };
}
