var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
var clientAPI = function (_a) {
    var _b = _a === void 0 ? {} : _a, headers = _b.headers;
    return axios.create({
        withCredentials: false,
        headers: __assign({ Accept: 'application/json', 'Content-Type': 'application/json' }, headers)
    });
};
export default clientAPI;
